<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
        <div>
          <button class="btn btn-success" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Customer"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Customer ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
           <b-form-group
            id="rContentInputGroup4"
              label="Search By Status"
              label-for="rContent">
                <b-form-select id="v_type"
                  v-model="filterStatus"
                  :options="[{value: '', text:'Choose Status'},
                    {value: 'pending', text: 'Menunggu Pembayaran'},
                    {value: 'cancelled', text: 'Dibatalkan'},
                    {value: 'expired', text: 'Expired'},
                    {value: 'processing', text: 'Sedang Diproses'},
                    {value: 'awaiting-shipment', text: 'Sedang Dikirim'},
                    {value: 'completed', text: 'Selesai'}]"
                  aria-describedby="statusFeedback"
                  :plain="true"
                  @change="doFilterStatus">
                </b-form-select>
            </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="name-slot" slot-scope="prop">
          <router-link :to="onAction('view-user', prop.rowData, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }} ({{ prop.rowData.users.email }})</router-link>
          <span></span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button class="btn btn-success" v-if="$can('detail_redeem')"
              @click="onAction('view-item', prop.rowData)">
              Detail
            </button>&nbsp;
            <!-- <button class="btn btn-info" v-if="$can('detail_redeem')"
              @click="onAction('edit-item', prop.rowData)">
              Edit
            </button> -->
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        filterText: '',
        filterStatus: '',
        isLoading: false,
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `gold-reedems`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'created_at',
            sortField: 'created_at',
            title: 'Transaction Date'
          },
          {
            name: 'name-slot',
            sortField: 'users.name',
            title: 'User'
          },
          {
            name: 'partner_name',
            sortField: 'partner_name',
            title: 'Partner',
            formatter: this.partVal
          },
          {
            name: 'invoice_number',
            sortField: 'invoice_number',
            title: 'Invoice No',
          },
          {
            name: 'total_gram',
            sortField: 'total_gram',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: 'Total Gram',
            formatter: this.gramVal
          },
          {
            name: 'status',
            sortField: 'status',
            title: 'Transaction Status'
          },
          {
            name: 'actions-slot',
            title: 'Detail',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    methods: {
      partVal(value) {
        return value ? value : "-"
      },
      gramVal(value) {
        return value + ' Gram'
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset', 'filter')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`redeem/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      doFilterStatus () {
        this.$events.$emit('status-set', this.filterStatus)
      },
      onDateSet()  {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key !== 'end_to')))
        
        this.moreParams = {
          ...newParams,
          'start_from': this.start_from = this.startDate,
          'end_to': this.end_to = this.endDate
        }
        
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'view-user') {
          return { name: 'Detail End User', params: { id: btoa(data.users.id)} }
        }else if (action == "view-item") {
          this.$router.push({name: 'Detail Cetak Emas', params: { id: data.id, type: data.type}})
        }else{
          this.$router.push({name: 'Edit Cetak Emas', params: { id: data.id}})
        }
      },
      onFilterSet (filterText) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        
        this.moreParams = {
          ...newParams,
          'filter': filterText
        }

        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterStatusSet() {
        if(this.filterStatus === ''){
          this.onFilterReset('status')
        }else{
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'status'))
        
          this.moreParams = {
            ...newParams,
            'status': this.filterStatus
          }

          Vue.nextTick(() => this.$refs.vuetable.refresh())
        }
      },
      onFilterReset (value) {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== value))
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key !== 'end_to')))
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('filter-reset', eventData => this.onFilterReset(eventData))
      this.$events.$on('status-set', eventData => this.onFilterStatusSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />

